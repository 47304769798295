<template>
  <div class="flex w-full flex-col items-center justify-between pb-32">
    <div v-if="$store.state.user" class="flex w-full flex-row items-center justify-center space-x-4 sm:justify-end">
      <span class="text-400 font-semibold">{{ $store.state.user.first_name }} {{ $store.state.user.last_name }}</span>
      <UserAvatar size="lg" :user="$store.state.user" />
      <span
        class="cursor-pointer select-none text-300 font-semibold text-primary-800 hover:text-primary"
        @click="logout"
        >Logout</span
      >
    </div>

    <div class="flex flex-col gap-8 items-center justify-center w-full h-full">
      <div class="mt-8 flex w-full items-center justify-center sm:mt-32">
        <form @submit.prevent="search" class="mt-4 flex w-full max-w-3xl flex-col gap-4" autocomplete="off">
          <InputElement
            ref="inputElement"
            v-model="searchQuery"
            type="text"
            class="w-full"
            :placeholder="$t('Dashboard.Search.Placeholder')"
          />

          <ButtonElement
            type="submit"
            :text="$t('Dashboard.Search.Action.Search')"
            variant="primary"
            size="md"
            class="flex-shrink-0"
          />
        </form>
      </div>

      <div
        class="border relative mt-8 grid p-4 gap-2 w-full max-w-3xl grid-cols-3 rounded-md border-neutral-50 bg-white"
      >
        <span class="col-span-3 text-center flex items-center justify-center text-500 font-bold text-neutral-600">{{
          monthName
        }}</span>

        <div
          class="flex w-full flex-col items-center gap-y-2 p-4 text-300"
          v-tippy="{ arrow: true }"
          :content="$t('Dashboard.Revenue.Explanation')"
        >
          <span class="text-neutral-700">{{ $t('Dashboard.Revenue') }}</span>
          <span class="text-600 font-bold">{{ (revenue * 100) | money }}</span>
        </div>

        <div
          class="flex w-full flex-col items-center gap-y-2 p-4 text-300"
          v-tippy="{ arrow: true }"
          :content="$t('Dashboard.Receivables.Explanation')"
        >
          <span class="text-neutral-700">{{ $t('Dashboard.Receivables') }}</span>
          <span class="text-600 font-bold">{{ (receivable * 100) | money }}</span>
        </div>

        <div
          class="flex w-full flex-col items-center gap-y-2 p-4 text-300"
          v-tippy="{ arrow: true }"
          :content="$t('Dashboard.Total.Explanation')"
        >
          <span class="text-neutral-700">{{ $t('Dashboard.Total') }}</span>
          <span class="text-600 font-bold">{{ ((revenue + receivable) * 100) | money }}</span>
        </div>

        <div class="flex w-full flex-col items-center gap-y-2 p-4 text-300">
          <span class="text-neutral-700">{{ $t('Video.Stats.UploadsLastHour') }}</span>
          <span v-if="overviewResponse" class="text-600 font-bold">{{ overviewResponse.last_hour_uploads }}</span>
        </div>

        <div class="flex w-full flex-col items-center gap-y-2 p-4 text-300">
          <span class="text-neutral-700">{{ $t('Video.Stats.TodayCreditsAmount') }}</span>
          <span v-if="overviewResponse" class="text-600 font-bold">&euro; {{ overviewResponse.credits_today }}</span>
        </div>

        <div class="flex w-full flex-col items-center gap-y-2 p-4 text-300">
          <span class="text-neutral-700">{{ $t('Video.Stats.LastCreditsDate') }}</span>
          <span v-if="overviewResponse" class="text-400 font-bold">{{
            overviewResponse.credits_last_date | timeago(true)
          }}</span>
        </div>
      </div>
    </div>

    <div class="grow"></div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {
      searchQuery: '',
      revenue: 0,
      receivable: 0,
      overviewResponse: null
    };
  },
  computed: {
    monthStart() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), 1);
    },

    monthEnd() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth() + 1, 0);
    },

    monthName() {
      return this.monthStart.toLocaleString('default', { month: 'long' });
    }
  },
  mounted() {
    this.refresh();

    this.$refs.inputElement.focus();
    setTimeout(() => {
      this.$refs.inputElement.focus();
    }, 200);
  },
  methods: {
    async search() {
      this.$router.push({ name: 'Search', params: { query: this.searchQuery } });
    },

    async refresh() {
      this.overviewResponse = await this.$api.get('/admin/management/video/overview');

      const organizationsResponse = await this.$api.get(
        `/admin/finance/organizations?start=${this.monthStart.getTime() / 1000}&end=${this.monthEnd.getTime() / 1000}`
      );
      const videosResponse = await this.$api.get(
        `/admin/finance/videos?start=${this.monthStart.getTime() / 1000}&end=${this.monthEnd.getTime() / 1000}`
      );

      this.revenue += videosResponse.total.revenue;
      const keys = Object.keys(organizationsResponse.selected_period.revenue);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        this.revenue += organizationsResponse.selected_period.revenue[key].revenue;
        this.receivable += organizationsResponse.selected_period.revenue[key].receivable;
      }
    },

    logout() {
      this.$modal.confirm(() => {
        this.$app.logout();
      });
    }
  }
};
</script>
