import Vue from 'vue';
import VueRouter from 'vue-router';
import GuardActiveSession from '@/router/guards/activesession';
import GuardInactiveSession from '@/router/guards/inactivesession';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    alias: ['/dashboard'],
    name: 'Dashboard',
    menuName: 'Dashboard',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Dashboard/Dashboard.vue')
  },
  {
    path: '/search',
    name: 'Search',
    menuName: 'Search',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Search/Search.vue')
  },
  {
    path: '/finance/timeline',
    name: 'FinanceTimeline',
    menuName: 'Finance - Timeline',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Finance/FinanceTimeline.vue')
  },
  {
    path: '/finance/videos',
    name: 'FinanceVideos',
    menuName: 'Finance - Videos',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Finance/FinanceVideos.vue')
  },
  {
    path: '/finance/organizations',
    name: 'FinanceOrganizations',
    menuName: 'Finance - Organizations',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Finance/FinanceOrganizations.vue')
  },
  {
    path: '/finance/invoices',
    name: 'FinanceInvoices',
    menuName: 'Finance - Invoices',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Finance/FinanceInvoices.vue')
  },
  {
    path: '/finance/upcoming',
    name: 'FinanceUpcoming',
    menuName: 'Finance - Upcoming',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Finance/FinanceUpcoming.vue')
  },
  {
    path: '/system',
    name: 'System',
    menuName: 'System',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/System/System.vue')
  },
  {
    path: '/configuration',
    name: 'Configuration',
    menuName: 'Configuration',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Configuration/Configuration.vue')
  },
  {
    path: '/management/video/overview',
    name: 'VideoOverview',
    menuName: 'Management - VideoOverview',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Management/VideoOverview.vue')
  },
  {
    path: '/management/comfort/overview',
    name: 'Comfort',
    menuName: 'Management - Comfort',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Management/Comfort.vue')
  },
  {
    path: '/management/comfort/usage',
    name: 'ComfortUsage',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Management/ComfortUsage.vue')
  },
  {
    path: '/management/blacklist/overview',
    name: 'BlacklistOverview',
    menuName: 'Management - BlacklistOverview',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Management/BlacklistOverview.vue')
  },
  {
    path: '/management/video/:videoID',
    name: 'Video',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Management/Video.vue')
  },
  {
    path: '/management/user/:userID',
    name: 'User',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Management/User.vue')
  },
  {
    path: '/management/invoice/:invoiceID',
    name: 'Invoice',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Management/Invoice.vue')
  },
  {
    path: '/management/organization/:organizationID',
    name: 'Organization',
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    component: () => import('../views/Management/Organization.vue')
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: GuardInactiveSession({ name: 'Dashboard' }),
    component: () => import('../views/Login/Login.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
