<template>
  <div
    v-if="visible"
    class="absolute inset-0 flex h-full min-h-screen w-full cursor-default flex-col overflow-scroll bg-neutral-900 bg-opacity-25 pb-32"
    @click.self="$emit('hide')"
  >
    <div
      class="absolute top-0 right-0 bottom-0 flex h-full min-h-screen w-full max-w-md flex-col items-start justify-start bg-neutral-0 text-300 text-neutral-900"
    >
      <div class="my-8 flex w-full flex-row items-center justify-between px-8">
        <span class="flex-shrink-0 select-none text-600 font-bold">{{ $t('Management.Actions.Header') }}</span>
        <div class="flex-grow w-full"></div>
        <IconClose class="h-8 w-8 flex-shrink-0 cursor-pointer select-none" @click.native="$emit('hide')" />
      </div>
      <span
        v-for="(action, index) in actions"
        :key="index"
        @click="executeAction(action)"
        class="border-b w-full animate-slide-x-in cursor-pointer select-none border-neutral-50 py-4 px-8 font-semibold hover:bg-neutral-100"
        :class="{
          [`font-bold text-secondary`]: action.startsWith('delete')
        }"
      >
        {{ $t(`Management.Action.${action}`, [$store.state.user.username]) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionsPanel',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    video: {
      type: Object,
      required: false,
      default: null
    },
    user: {
      type: Object,
      required: false,
      default: null
    },
    purchase: {
      type: Object,
      required: false,
      default: null
    },
    invoice: {
      type: Object,
      required: false,
      default: null
    },
    organization: {
      type: Object,
      required: false,
      default: null
    }
  },
  watch: {
    video: {
      handler() {
        this.generateActions();
      },
      immediate: true
    },
    user: {
      handler() {
        this.generateActions();
      },
      immediate: true
    },
    purchase: {
      handler() {
        this.generateActions();
      },
      immediate: true
    },
    invoice: {
      handler() {
        this.generateActions();
      },
      immediate: true
    },
    organization: {
      handler() {
        this.generateActions();
      },
      immediate: true
    }
  },
  data() {
    return {
      actions: []
    };
  },
  methods: {
    generateActions() {
      this.actions = [];

      if (this.video) {
        this.generateVideoActions();
      }

      if (this.invoice) {
        this.generateInvoiceActions();
      }

      if (this.organization) {
        this.generateOrganizationActions();
      }

      if (this.user) {
        this.generateUserActions();
      }
    },

    generateVideoActions() {
      this.actions.push('open-editor');

      if (!this.purchase) {
        return;
      }

      if (this.purchase.plan_id === 1 || (this.purchase.plan_id < 7 && this.purchase.organization_id > 0)) {
        this.actions.push('upgrade');

        if (this.purchase.organization_id > 0) {
          this.actions.push('upgrade-professional');
          this.actions.push('downgrade');
        }
      } else if ([3, 4, 7].includes(this.purchase.plan_id)) {
        this.actions.push('downgrade');
      }

      if (!this.purchase.paid) {
        this.actions.push('mark-paid');
      }

      this.actions.push('reload-thumbnail-all');
      this.actions.push('reload-thumbnail-preview');
      this.actions.push('force-retranscribe');
      this.actions.push('send-human-assistance');
      this.actions.push('fix-uploaded-file');
      this.actions.push('resend-video-ready-email');
      this.actions.push('video-soft-delete');
      this.actions.push('reset-subtitles');
      this.actions.push('reset-render-status');
      this.actions.push('resolve-stuck-status');
    },

    generateInvoiceActions() {
      this.actions.push('open-chase-email');
      this.actions.push('mark-paid');
      this.actions.push('delete-invoice');
    },

    generateUserActions() {
      // this.actions.push('change-password');
      this.actions.push('delete-user');
    },

    generateOrganizationActions() {
      this.actions.push('assign-current-user');
      this.actions.push('toggle-pay-by-invoice');

      const expiryDate = new Date(this.organization.organization_plan_expiry_date);
      const now = new Date();
      if (
        now.getTime() > expiryDate.getTime() &&
        (this.organization.organization_plan_status === 'active' ||
          this.organization.organization_plan_status === 'pay-by-invoice')
      ) {
        this.actions.push('extend-subscription-by-one-month');
      }

      if (this.organization.organization_plan_status !== 'active') {
        this.actions.push('enable-subscription');
      } else {
        this.actions.push('cancel-subscription');

        const cancelDate = new Date(this.organization.organization_plan_start_date);
        cancelDate.setMonth(cancelDate.getMonth() + 12);
        if (now.getTime() < cancelDate.getTime()) {
          this.actions.push('enable-cancellation');
        }
      }

      this.actions.push('delete-organization');
    },

    openEditor() {
      window.open(`${this.$store.getters.editorBaseURL}/${this.video.uuid}?ghost=1`);
    },

    openChaseEmail() {
      const invoiceDate = new Date(this.invoice.create_date);

      const subject = `Triple8 - Factuur #${this.invoice.id}`;
      const text = encodeURIComponent(
        `Beste ${this.invoice.organization.first_name},\n\nIk zie dat factuur #${
          this.invoice.id
        } van ${invoiceDate.toLocaleDateString()} nog open staat.\nZou je eens willen kijken of deze ergens is blijven hangen?`
      );
      window.location = `mailto:${this.invoice.organization.email}?subject=${subject}&body=${text}`;
    },

    resolveStuckVideoStatus() {
      this.$emit('change', {
        'video.editor_url': `https://editor.triple8.tv/${this.video.uuid}`
      });
    },

    executeAction(action) {
      if (action === 'resolve-stuck-status') {
        this.resolveStuckVideoStatus();
        return;
      }

      if (action === 'open-editor') {
        this.openEditor();
        return;
      }

      if (action === 'open-chase-email') {
        this.openChaseEmail();
        return;
      }

      let type = null;
      let identifier = 0;

      if (this.video) {
        type = 'video';
        identifier = this.video.id;
      } else if (this.invoice) {
        type = 'invoice';
        identifier = this.invoice.id;
      } else if (this.organization) {
        type = 'organization';
        identifier = this.organization.id;
      } else if (this.user) {
        type = 'user';
        identifier = this.user.id;
      }

      if (!type || type.length === 0) {
        return;
      }

      this.$modal.confirm(async () => {
        if (action === 'force-retranscribe') {
          this.$store.commit('forceRetranscribe', identifier);
        }

        const { message } = await this.$api.post(`/admin/management/${type}/${identifier}/${action}`);
        if (message) {
          this.$modal.show('AlertModal', {
            message
          });
        }
        this.$emit('refresh');
      });
    }
  }
};
</script>
